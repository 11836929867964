import {UploadDropzone} from 'react-uploader'
import {Uploader} from 'uploader' // Installed by "react-uploader".
import {UPDATE_PROFILE_PHOTO} from '../../../scripts/api'
import {patchData} from '../../../scripts/api-service'

const uploader = Uploader({
  apiKey: 'free', // Get production API keys from Bytescale
})

// Configuration options: https://www.bytescale.com/docs/upload-widget/frameworks/react#customize
const options = {multi: false}

export default function ProfilePhotoUpdate() {
  const uploadPhoto = async (files) => {
    let file = files?.at(0)?.originalFile?.file
    const formData = new FormData()
    formData.append('photo', file)

    let res = await patchData(UPDATE_PROFILE_PHOTO, formData)

    if (res) {
      console.log(res)
    }
  }

  return (
    <div>
      <UploadDropzone
        uploader={uploader}
        options={options}
        onUpdate={(files) => uploadPhoto(files)}
        width='600px'
        height='375px'
      />
    </div>
  )
}
