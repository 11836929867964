import {createContext, useContext, useState} from 'react'
import {GET_PROFILE} from '../scripts/api'
import {getData} from '../scripts/api-service'

const UserContext = createContext()

export const UserProvider = ({children}) => {
  const [user, setUser] = useState(null)

  const setUserProfile = async () => {
    let res = await getData(GET_PROFILE)

    if (res) {
      setUser(res)
    }
  }

  return (
    <UserContext.Provider value={{user, setUserProfile}}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)
