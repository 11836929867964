import {zodResolver} from '@hookform/resolvers/zod'
import {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {z} from 'zod'
import {UPDATE_PROFILE} from '../../../scripts/api'
import {putData} from '../../../scripts/api-service'
import {useToast} from '../../hooks/use-toast'
import {Button} from '../../ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../../ui/form'
import {Input} from '../../ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../ui/select'

import {useUser} from '../../../context/UserProfile'

// Define the validation schema using zod
const formSchema = z.object({
  first_name: z
    .string()
    .min(2, {message: 'First name must be at least 2 characters'}),
  last_name: z
    .string()
    .min(2, {message: 'Last name must be at least 2 characters'}),
  date_of_birth: z.string().min(1, {message: 'Please select a date of birth'}),
  gender: z.string().min(1, {message: 'Please select a gender'}),
  phone_number: z.string().min(10, {message: 'Phone number must be valid'}),
  organization: z.string().optional(),
  address: z
    .string()
    .min(5, {message: 'Address must be at least 5 characters'}),
  city: z.string().min(2, {message: 'City must be at least 2 characters'}),
  country: z.string().min(1, {message: 'Please select a country'}),
})

export default function ProfileUpdate() {
  // const router = useRouter()
  const {user} = useUser()
  const navigate = useNavigate()
  const {toast} = useToast()
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      date_of_birth: '',
      gender: '',
      phone_number: '',
      organization: '',
      address: '',
      city: '',
      country: '',
    },
  })

  const onSubmit = async (data) => {
    let res = await putData(UPDATE_PROFILE, data)

    if (res) {
      if (res.error) {
        Object.keys(res.errors).forEach((field) => {
          if (field === 'non_field_errors') {
            toast({
              variant: 'destructive',
              title: res.errors[field],
            })
          } else {
            form.setError(field, {
              type: 'manual',
              message: res.errors[field],
            })
          }
        })
      } else {
        let masterData = res?.data
        toast({
          variant: 'success',
          title: masterData?.detail,
        })
        navigate('/signin')
      }
    }
  }

  useEffect(() => {
    if (user) {
      form.setValue('address', user.address)
      form.setValue('city', user.city)
      form.setValue('date_of_birth', user.date_of_birth)
      form.setValue('email', user.email)
      form.setValue('first_name', user.first_name)
      form.setValue('last_name', user.last_name)
      form.setValue('organization', user.organization)
      form.setValue('phone_number', user.phone_number)
      form.setValue('gender', user.gender)
      form.setValue('country', user.country)
    }
  }, [user])

  return (
    <div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit, (errors) => {
            console.log(errors)
          })}
          className='grid grid-rows-6  grid-cols-2 gap-4'
        >
          {/* <div className=""> */}
          {/* First Name */}
          <FormField
            control={form.control}
            name='first_name'
            render={({field}) => (
              <FormItem>
                {/* <FormLabel>First Name</FormLabel> */}
                <FormControl>
                  <Input
                    className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'
                    placeholder='First name'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Last Name */}
          <FormField
            control={form.control}
            name='last_name'
            render={({field}) => (
              <FormItem>
                {/* <FormLabel>Last Name</FormLabel> */}
                <FormControl>
                  <Input
                    className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'
                    placeholder='Last name'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Phone Number */}
          <FormField
            control={form.control}
            name='phone_number'
            render={({field}) => (
              <FormItem>
                {/* <FormLabel>Phone Number</FormLabel> */}
                <FormControl>
                  <Input
                    className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'
                    placeholder='Enter your phone number'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Date of Birth */}
          <FormField
            control={form.control}
            name='date_of_birth'
            render={({field}) => (
              <FormItem>
                {/* <FormLabel>Date of Birth</FormLabel> */}
                <FormControl>
                  <Input
                    className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'
                    type='date'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Gender Select */}
          <FormField
            control={form.control}
            name='gender'
            render={({field}) => (
              <FormItem>
                {/* <FormLabel>Gender</FormLabel> */}
                <FormControl>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'>
                      <SelectValue placeholder='Select a gender' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value='MALE'>Male</SelectItem>
                      <SelectItem value='FEMALE'>Female</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Organization */}
          <FormField
            control={form.control}
            name='organization'
            render={({field}) => (
              <FormItem>
                {/* <FormLabel>Organization</FormLabel> */}
                <FormControl>
                  <Input
                    className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'
                    placeholder='Organization (optional)'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Address */}
          <FormField
            control={form.control}
            name='address'
            render={({field}) => (
              <FormItem>
                {/* <FormLabel>Address</FormLabel> */}
                <FormControl>
                  <Input
                    className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'
                    placeholder='Address'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* City */}
          <FormField
            control={form.control}
            name='city'
            render={({field}) => (
              <FormItem>
                {/* <FormLabel>City</FormLabel> */}
                <FormControl>
                  <Input
                    className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'
                    placeholder='City'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Country Select */}
          <FormField
            control={form.control}
            name='country'
            render={({field}) => (
              <FormItem>
                {/* <FormLabel>Country</FormLabel> */}
                <FormControl>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'>
                      <SelectValue placeholder='Select a country' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value='USA'>USA</SelectItem>
                      <SelectItem value='Canada'>Canada</SelectItem>
                      <SelectItem value='UK'>UK</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* </div> */}

          {/* Submit Button */}
          <Button
            className='col-span-2 p-[16px] w-full h-[48px] bg-[#000A11] dark:bg-gray-700 rounded-[12px] font-medium text-[14px] leading-[140%] text-[#FFFFFF]'
            type='submit'
          >
            Submit
          </Button>
        </form>
      </Form>
    </div>
  )
}
