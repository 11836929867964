import {zodResolver} from '@hookform/resolvers/zod'
import Cookies from 'js-cookie'
import {toast} from '../../components/hooks/use-toast'
import {Button} from '../../components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../../components/ui/form'
import {Input} from '../../components/ui/input'
import {useChannelSettings} from '../../context/ChannelSettings'
import {TOKEN} from '../../scripts/api'
import {postData} from '../../scripts/api-service'
// import {useRouter} from 'next/navigation'
import {useNavigate} from 'react-router-dom'

import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {z} from 'zod'

// Define the validation schema using zod
const loginSchema = z.object({
  email: z
    .string()
    .email({message: 'Please enter a valid email address.'})
    .nonempty({message: 'Email is required.'}),
  password: z
    .string()
    .min(6, {message: 'Password must be at least 6 characters.'})
    .nonempty({message: 'Password is required.'}),
})

export default function Signin() {
  const {channelSettings} = useChannelSettings()
  const navigate = useNavigate()

  // const router = useRouter()
  // Initialize react-hook-form with zod validation
  const form = useForm({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = async (data) => {
    let res = await postData(TOKEN, data)

    if (res) {
      if (res.error) {
        Object.keys(res.errors).forEach((field) => {
          if (field === 'detail') {
            toast({
              variant: 'destructive',
              title: res.errors[field],
            })
          } else {
            form.setError(field, {
              type: 'manual',
              message: res.errors[field],
            })
          }
        })
      } else {
        let masterData = res.data

        Cookies.set('kotha_token', masterData?.access)
        Cookies.set('kotha_refresh_token', masterData?.refresh)
        navigate('/chat')
      }
    }
  }

  return (
    <div className='bg-white dark:bg-gray-900 gap-6 md:mx-5 w-full md:w-[460px] border border-[#E4E4E4] dark:border-gray-600 rounded-[16px] p-[24px] flex flex-col items-center'>
      <Link to='/'>
        <img
          src={channelSettings?.logo_dark}
          alt={'logo'}
          className='mx-auto w-[150px] h-[40px]'
        />
      </Link>

      <div className='font-bold text-[24px] leading-[150%] text-[#000A11] dark:text-white'>
        Sign in to Kotha.ai
      </div>

      <div className='w-auto md:w-[410px]'>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-4'>
            {/* Email Field */}
            <FormField
              control={form.control}
              name='email'
              render={({field}) => (
                <FormItem>
                  {/* <FormLabel>Email</FormLabel> */}
                  <FormControl>
                    <Input
                      className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'
                      placeholder='Email address'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Password Field */}
            <FormField
              control={form.control}
              name='password'
              render={({field}) => (
                <FormItem>
                  {/* <FormLabel>Password</FormLabel> */}
                  <FormControl>
                    <Input
                      className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'
                      type='password'
                      placeholder='Enter your password'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className='p-0 w-full'>
              {/* <div className="flex items-center space-x-2">
              <Checkbox
                id="remember"
                {...register("remember", {
                  required: "You must agree to the terms",
                })}
              />
              <label
                htmlFor="remember"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 dark:text-gray-400"
              >
                Remember me
              </label>
            </div> */}

              <div className='text-right'>
                <Link
                  to='/forget-password'
                  className='font-medium text-[14px] leading-[140%] text-[#000A11] dark:text-gray-400'
                >
                  Forgot password
                </Link>
              </div>
            </div>

            {/* Submit Button */}
            <Button type='submit' className='w-full'>
              Login
            </Button>

            <div className='text-center'>
              Don’t have an account?
              <Link
                to='/signup'
                className='font-medium text-[14px] leading-[140%] text-[#000A11] dark:text-gray-400'
              >
                {' '}
                Create account
              </Link>
            </div>
          </form>
        </Form>
      </div>
    </div>
  )
}
