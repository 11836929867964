import {HelmetProvider} from 'react-helmet-async'
import './App.css'
import {Toaster} from './components/ui/toaster'
import {ChannelSettingsProvider} from './context/ChannelSettings'
import {ThemeProvider} from './context/ThemeContext'
import {UserProvider} from './context/UserProfile'
import Routers from './Routers'
import './style/style.scss'

function App() {
  // const {channelSettings} = useChannelSettings()
  return (
    <HelmetProvider>
      <ChannelSettingsProvider>
        <UserProvider>
          <ThemeProvider>
            <div className='App'>
              <Routers />
            </div>
            <Toaster />
          </ThemeProvider>
        </UserProvider>
      </ChannelSettingsProvider>
    </HelmetProvider>
  )
}

export default App
