export const TOKEN = '/api/user/token/'
export const TOKEN_REFRESH = '/api/user/token/refresh/'
export const REGISTER = '/api/user/register/'
export const LOGOUT = '/api/user/logout/'
export const GET_PROFILE = '/api/user/profile/'
export const UPDATE_PROFILE = '/api/user/profile/'
export const CHANNEL_SETTING = '/api/chat/channel-settings/'
export const LIVE_CHAT = '/api/chat/livechat/'
export const CHAT_HISTORY = '/api/chat/chat-history/'
export const PASSWORD_RESET = '/api/user/password/reset/'
export const PASSWORD_RESET_CONFIRM = '/api/user/password/reset/confirm/'
export const PASSWORD_CHANGE = '/api/user/password/change/'
export const GET_CHANNEL_SETTINGS = '/api/chat/channel-settings/'
export const UPDATE_PROFILE_PHOTO = '/api/user/profile-photo/'
