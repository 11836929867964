import {createContext, useContext, useEffect, useState} from 'react'
import {GET_CHANNEL_SETTINGS} from '../scripts/api'
import {postData} from '../scripts/api-service'

const ChannelSettingsContext = createContext()

export const ChannelSettingsProvider = ({children}) => {
  const [channelSettings, setChannelSettings] = useState(null)

  const setChannels = (value) => {
    setChannelSettings(value)
  }

  const getChannelSettings = async () => {
    const res = await postData(GET_CHANNEL_SETTINGS, [
      {
        channel: 'kotha',
      },
    ])

    if (res) {
      setChannelSettings(res.data)
    }
  }

  useEffect(() => {
    getChannelSettings()
  }, [])

  return (
    <ChannelSettingsContext.Provider value={{channelSettings, setChannels}}>
      {children}
    </ChannelSettingsContext.Provider>
  )
}

export const useChannelSettings = () => useContext(ChannelSettingsContext)
