import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import {z} from 'zod'
import {PASSWORD_CHANGE} from '../../../scripts/api'
import {postData} from '../../../scripts/api-service'
import {useToast} from '../../hooks/use-toast'
import {Button} from '../../ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../../ui/form'
import {Input} from '../../ui/input'

// Define the validation schema using zod
const formSchema = z.object({
  old_password: z
    .string()
    .min(6, {message: 'Password must be at least 6 characters.'})
    .nonempty({message: 'Password is required.'}),
  new_password1: z
    .string()
    .min(6, {message: 'Password must be at least 6 characters.'})
    .nonempty({message: 'Password is required.'}),
  new_password2: z
    .string()
    .min(6, {message: 'Password must be at least 6 characters.'})
    .nonempty({message: 'Password is required.'}),
})
export default function ChangePasword() {
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      new_password1: '',
      old_password: '',
      new_password2: '',
    },
  })

  const {toast} = useToast()

  const onSubmit = async (data) => {
    console.log('data', data)

    let res = await postData(PASSWORD_CHANGE, data)

    if (res) {
      if (res.error) {
        Object.keys(res.errors).forEach((field) => {
          if (field === 'non_field_errors') {
            toast({
              variant: 'destructive',
              title: res.errors[field],
            })
          } else {
            form.setError(field, {
              type: 'manual',
              message: res.errors[field],
            })
          }
        })
      } else {
        let masterData = res?.data
        toast({
          variant: 'success',
          title: masterData?.detail,
        })
      }
    }
  }

  return (
    <div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit, (errors) => {
            console.log(errors)
          })}
          className='flex flex-col gap-6 p-5'
        >
          {/* <div className=""> */}
          <FormField
            control={form.control}
            name='old_password'
            render={({field}) => (
              <FormItem>
                {/* <FormLabel>Password</FormLabel> */}
                <FormControl>
                  <Input
                    className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'
                    type='password'
                    placeholder='Enter your old password'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='new_password1'
            render={({field}) => (
              <FormItem>
                {/* <FormLabel>Password</FormLabel> */}
                <FormControl>
                  <Input
                    className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'
                    type='password'
                    placeholder='Enter your new password '
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='new_password2'
            render={({field}) => (
              <FormItem>
                {/* <FormLabel>Password</FormLabel> */}
                <FormControl>
                  <Input
                    className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'
                    type='password'
                    placeholder='Enter your confirm password'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* </div> */}

          {/* Submit Button */}
          <Button
            className='col-span-2 p-[16px] w-full h-[48px] bg-[#000A11] dark:bg-gray-700 rounded-[12px] font-medium text-[14px] leading-[140%] text-[#FFFFFF]'
            type='submit'
          >
            Submit
          </Button>
        </form>
      </Form>
    </div>
  )
}
