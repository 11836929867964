import {Helmet} from 'react-helmet-async'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {useChannelSettings} from './context/ChannelSettings'
import AuthLayout from './layouts/AuthLayout'
import PrivateLayout from './layouts/PrivateLayout'
import ForgetPassword from './pages/Auth/ForgetPassword'
import Home from './pages/Auth/Home'
import ResetPassword from './pages/Auth/ResetPassword'
import Signin from './pages/Auth/Signin'
import Signup from './pages/Auth/Signup'
import Chat from './pages/private/Chat'

export default function Routers() {
  const {channelSettings} = useChannelSettings()

  console.log('channelSettings', channelSettings)

  return (
    <>
      {channelSettings ? (
        <>
          <Helmet>
            <title>{channelSettings?.site_title}</title>
            <link rel='icon' href={channelSettings?.favicon} />
            <meta property='og:title' content={channelSettings?.site_title} />
            <link rel='canonical' href='https://www.kotha.ai/' />
            <meta name='keywords' content={channelSettings?.site_keywords} />
            <meta
              name='description'
              content={channelSettings?.site_description}
            ></meta>
          </Helmet>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<AuthLayout />}>
                <Route path='/' element={<Home />} />
                <Route path='/signin' element={<Signin />} />
                <Route path='/signup' element={<Signup />} />
                <Route path='/forget-password' element={<ForgetPassword />} />
                <Route path='/reset-password' element={<ResetPassword />} />
              </Route>
              <Route element={<PrivateLayout />}>
                <Route path='chat' element={<Chat />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </>
      ) : (
        <>
          <img
            src='./assets/icons/Spinne.gif'
            alt='loading'
            className='mx-auto'
          />
        </>
      )}
    </>
  )
}
