import {useState} from 'react'
import {IoSettingsOutline} from 'react-icons/io5'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import ChangePasword from './Settings/ChangePasword'
import Notification from './Settings/Notification'
import ProfileUpdate from './Settings/ProfileUpdate'

export default function SettingMenu() {
  const [active, setActive] = useState('general')
  return (
    <div>
      <Dialog>
        <DialogTrigger>
          <li className='flex items-center px-[12px] gap-[10px] rounded-[8px] cursor-pointer dark:text-white'>
            <IoSettingsOutline /> Settings
          </li>
        </DialogTrigger>
        <DialogContent className='max-w-fit'>
          <DialogHeader>
            <DialogTitle className='py-3'>Settings</DialogTitle>
            <DialogDescription className='border-t'>
              <div className='flex flex-row items-start p-0'>
                <div className='flex flex-col items-center gap-4 py-4 pr-3 border-r border-[#E4E4E4]'>
                  <div
                    className={`w-40 h-10 px-4 pt-2 cursor-pointer ${
                      active === 'general'
                        ? 'bg-[#E4E4E4] rounded-[8px]'
                        : 'border-b'
                    }`}
                    onClick={() => {
                      setActive('general')
                    }}
                  >
                    General
                  </div>
                  <div
                    className={`w-40 h-10 px-4 pt-2 cursor-pointer ${
                      active === 'profile'
                        ? 'bg-[#E4E4E4] rounded-[8px]'
                        : 'border-b'
                    }`}
                    // "w-40 h-10 px-4 pt-2 border-b cursor-pointer"
                    onClick={() => {
                      setActive('profile')
                    }}
                  >
                    Profile
                  </div>
                  <div
                    className={`w-40 h-10 px-4 pt-2 cursor-pointer ${
                      active === 'security'
                        ? 'bg-[#E4E4E4] rounded-[8px]'
                        : 'border-b'
                    }`}
                    // "w-40 h-10 px-4 pt-2 border-b cursor-pointer"
                    onClick={() => {
                      setActive('security')
                    }}
                  >
                    Security
                  </div>
                  <div
                    className={`w-40 h-10 px-4 pt-2 cursor-pointer ${
                      active === 'notification'
                        ? 'bg-[#E4E4E4] rounded-[8px]'
                        : 'border-b'
                    }`}
                    // "w-40 h-10 px-4 pt-2 border-b cursor-pointer"
                    onClick={() => {
                      setActive('notification')
                    }}
                  >
                    Notification
                  </div>
                </div>
                <div className=''>
                  {/* General  */}
                  {active === 'general' ? (
                    <div className='flex flex-col items-start p-[16px] gap-[16px]'>
                      <div className='w-full flex flex-row justify-between items-center p-0 gap-[200px]'>
                        <div className='font-semibold text-[16px] leading-[140%] text-[#000A11]'>
                          Theme
                        </div>
                        <div>
                          <Select defaultValue='light'>
                            <SelectTrigger className='w-[100px]'>
                              <SelectValue placeholder='Theme' />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value='light' selected>
                                Light
                              </SelectItem>
                              <SelectItem value='dark'>Dark</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>

                      <div className='w-full flex flex-row justify-between items-center p-0 gap-[200px]'>
                        <div className='font-semibold text-[16px] leading-[140%] text-[#000A11]'>
                          Language
                        </div>
                        <div>
                          <Select defaultValue='en'>
                            <SelectTrigger className='w-[100px]'>
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value='en'>English</SelectItem>
                              <SelectItem value='bn'>Bangla</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>

                      <div className='w-full flex flex-row justify-between items-center p-0 gap-[200px]'>
                        <div className='font-semibold text-[16px] leading-[140%] text-[#000A11]'>
                          Voice
                        </div>
                        <div>
                          <Select defaultValue='oman'>
                            <SelectTrigger className='w-[100px]'>
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value='en'>English</SelectItem>
                              <SelectItem value='oman'>Oman</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {active === 'profile' ? (
                    <>
                      <ProfileUpdate />
                    </>
                  ) : null}

                  {active === 'security' ? (
                    <>
                      <ChangePasword />
                    </>
                  ) : null}

                  {active === 'notification' ? <Notification /> : null}
                </div>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  )
}
