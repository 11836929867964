import {Fragment} from 'react'
import {Link} from 'react-router-dom'
import Navbar from '../../components/common/Navbar'
import {useChannelSettings} from '../../context/ChannelSettings'

export default function Home() {
  const {channelSettings} = useChannelSettings()
  return (
    <Fragment>
      <Navbar />
      <section className='landing'>
        <div className='bg-content'>
          <div className='bg-content-color'></div>
        </div>
        <div className='container py-16 md:py-32'>
          <div className='flex'>
            <div className='relative inline-flex items-center m-auto align-middle'>
              <div className='max-w-3xl space-y-8 lg:text-center'>
                <img
                  src={channelSettings?.thumb} //'/assets/images/kotha.png'
                  height={106}
                  width={106}
                  alt='Kotha'
                  className='mx-auto'
                />
                <h1
                  data-animate='title'
                  className='font-bold text-[32px] md:text-6xl leading-[130%] text-center capitalize text-[#000A11] dark:text-[#FFFFFF]'
                  style={{opacity: 1, transform: 'translateY(0px)'}}
                >
                  {channelSettings?.channel_heading}
                </h1>
                <div className=''>
                  <p
                    data-animate='subtitle'
                    className='font-normal text-[15px] md:text-[20px] leading-[170%] text-center text-[#2D2D2D] dark:text-[#CCCCCC]'
                    style={{opacity: 1, transform: 'translateY(0px)'}}
                  >
                    {channelSettings?.channel_description}
                  </p>
                </div>
                <div className='flex justify-center mx-auto'>
                  <div className='px-[22px] py-[7px] w-[199px] h-[60px] bg-[#000A11] dark:bg-[#FFFFFF] rounded-[12px] flex justify-center items-center cursor-pointer'>
                    <Link
                      to={'/chat'}
                      className='font-normal text-[16px] leading-[140%] text-[#FFFFFF] dark:text-[#000A11]'
                    >
                      Try for Free
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}
