import Cookies from 'js-cookie'
import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import ChatContent from '../../../components/Share/ChatContent'
import ChatList from '../../../components/Share/ChatList'
import ChangePasword from '../../../components/Share/Settings/ChangePasword'
import ProfilePhotoUpdate from '../../../components/Share/Settings/ProfilePhotoUpdate'
import ProfileUpdate from '../../../components/Share/Settings/ProfileUpdate'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../components/ui/dialog'
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerTrigger,
} from '../../../components/ui/drawer'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../../../components/ui/dropdown-menu'
import {useChannelSettings} from '../../../context/ChannelSettings'
import {useTheme} from '../../../context/ThemeContext'
import {useUser} from '../../../context/UserProfile'
import {CHAT_HISTORY} from '../../../scripts/api'
import {postData} from '../../../scripts/api-service'

const timestampStart = Date.now()

export default function Chat() {
  const {channelSettings} = useChannelSettings()
  const {setUserProfile, user} = useUser()
  const {toggleTheme} = useTheme()
  const [timestamp, setTimestamp] = useState(timestampStart)
  const [chatHistory, setChatHistory] = useState([])
  const [selectedChat, setSelecetdChat] = useState([])
  const getChatHistory = async () => {
    let res = await postData(CHAT_HISTORY, [
      {
        channel: 'kotha',
      },
    ])

    if (res) {
      let masterData = res.data
      if (masterData?.at(0)) {
        setChatHistory(masterData)
        setSelecetdChat(masterData?.at(0)?.chats || [])
        setTimestamp(masterData?.at(0)?.session_id)
      }
    }
  }

  const LogOut = async () => {
    Cookies.remove('kotha_token')
    localStorage.removeItem('kotha_token')
    window.location = '/signin'
  }

  useEffect(() => {
    if (timestamp && chatHistory?.length) {
      let activechat = chatHistory.find((item) => item.session_id === timestamp)

      if (activechat) {
        setSelecetdChat(activechat?.chats || [])
      } else {
        setSelecetdChat([])
      }
    }
  }, [timestamp, chatHistory])

  useEffect(() => {
    getChatHistory()
    setUserProfile()
  }, [])
  return (
    <div className='main-chart-wrapper gap-0 md:flex dark:dark'>
      <div className='chat-info border hidden md:block bg-[#fff] dark:bg-zinc-800 dark:text-white dark:border-r-zinc-700 relative'>
        <div className='flex items-center justify-between w-full p-[19px] border-b dark:border-zinc-700'>
          <Link href='/'>
            <div className='flex flex-row items-center p-0 gap-[16px]'>
              <img
                src={channelSettings?.logo_dark}
                className='h-8 block dark:hidden'
                alt='Kotha Logo'
              />
              <img
                src={channelSettings?.logo_light}
                className='h-8 hidden dark:block'
                alt='Kotha Logo'
              />
              {/* <span className='font-bold text-[24px] leading-[29px] text-[#000A11] dark:text-white'>
                Kotha.ai
              </span> */}
            </div>
          </Link>
        </div>

        <ChatList
          chatHistory={chatHistory}
          selectedChat={selectedChat}
          setTimestamp={setTimestamp}
          timestamp={timestamp}
        />
      </div>

      <div className='card h-screen w-full relative'>
        <div className='flex flex-row justify-between items-center p-[16px] gap-[10px] border-b bg-[#FFFFFF] border-[#E4E4E4] dark:bg-zinc-800 dark:border-zinc-700 dark:text-white'>
          <div className='font-bold text-[24px] leading-[150%] text-[#000A11] dark:text-white hidden md:block'>
            New Chat
          </div>
          <div className='font-bold text-[24px] leading-[150%] text-[#000A11] dark:text-white md:hidden block'>
            <Drawer direction='left'>
              <DrawerTrigger>
                <img src='/assets/icons/nav.svg' alt='nav' />
              </DrawerTrigger>
              <DrawerContent className='h-full w-[350px] rounded-none'>
                <ChatList />
                <DrawerFooter></DrawerFooter>
              </DrawerContent>
            </Drawer>
          </div>
          <div className='flex flex-row items-center p-0 gap-[16px]'>
            <div className='hidden md:flex flex-row items-center p-[8px] gap-[8px] bg-[#E4E4E4] dark:bg-zinc-700 rounded-[12px]'>
              <span
                onClick={() => toggleTheme()}
                className='w-[24px] h-[24px] bg-[#FFFFFF] dark:bg-zinc-700 rounded-[4px] relative cursor-pointer'
              >
                <img
                  src='/assets/images/sun.png'
                  className='absolute top-[5px] left-[4px]'
                />
              </span>
              <span
                onClick={() => toggleTheme()}
                className='w-[24px] h-[24px] dark:bg-zinc-900 rounded-[4px] relative cursor-pointer'
              >
                <img
                  src='/assets/images/moon.png'
                  className='absolute top-[5px] left-[4px]'
                />
              </span>
            </div>
            <div>
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <img src='/assets/images/user.png' alt='kotha' />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuLabel>
                    <UserProfileUpdate />
                  </DropdownMenuLabel>
                  <DropdownMenuLabel>
                    <UserPasswordChange />
                  </DropdownMenuLabel>
                  <DropdownMenuLabel>
                    <UserProfilePicUpdate />
                  </DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuLabel
                    className='cursor-pointer'
                    onClick={() => {
                      LogOut()
                    }}
                  >
                    Logout
                  </DropdownMenuLabel>
                  {/* 
                  <DropdownMenuItem>Profile</DropdownMenuItem>
                  <DropdownMenuItem>Billing</DropdownMenuItem>
                  <DropdownMenuItem>Team</DropdownMenuItem>
                  <DropdownMenuItem>Subscription</DropdownMenuItem> */}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>

        <ChatContent
          selectedChat={selectedChat}
          setSelecetdChat={setSelecetdChat}
          timestamp={timestamp}
          username={user ? ', ' + user?.first_name + ' ' + user?.last_name : ''}
        />
      </div>
    </div>
  )
}

const UserProfileUpdate = () => {
  return (
    <Dialog>
      <DialogTrigger>Profile Update</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Profile Update</DialogTitle>
          <DialogDescription>
            <ProfileUpdate />
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

const UserPasswordChange = () => {
  return (
    <Dialog>
      <DialogTrigger>Change Password</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Change Password</DialogTitle>
          <DialogDescription>
            <ChangePasword />
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

const UserProfilePicUpdate = () => {
  return (
    <Dialog>
      <DialogTrigger>Profile Photo Update</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Profile Photo Update</DialogTitle>
          <DialogDescription>
            <ProfilePhotoUpdate />
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
