import {zodResolver} from '@hookform/resolvers/zod'
import {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {z} from 'zod'
import {useUser} from '../../../context/UserProfile'
import {UPDATE_PROFILE} from '../../../scripts/api'
import {putData} from '../../../scripts/api-service'
import {useToast} from '../../hooks/use-toast'
import {Button} from '../../ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../../ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../ui/select'

// Define the validation schema using zod
const formSchema = z.object({
  subscribe_to_notification: z.boolean({
    required_error: 'Please select an option',
  }),
})

export default function Notification() {
  const {user} = useUser()
  const {toast} = useToast()
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      subscribe_to_notification: false,
    },
  })

  const onSubmit = async (data) => {
    let res = await putData(UPDATE_PROFILE, data)

    if (res) {
      if (res.error) {
        Object.keys(res.errors).forEach((field) => {
          if (field === 'non_field_errors') {
            toast({
              variant: 'destructive',
              title: res.errors[field],
            })
          } else {
            form.setError(field, {
              type: 'manual',
              message: res.errors[field],
            })
          }
        })
      } else {
        let masterData = res?.data
        toast({
          variant: 'success',
          title: masterData?.detail,
        })
      }
    }
  }

  useEffect(() => {
    if (user) {
      form.setValue('subscribe_to_notification', user.subscribe_to_notification)
    }
  }, [user])

  return (
    <div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit, (errors) => {
            console.log(errors)
          })}
          className='flex flex-col gap-4 p-5'
        >
          {/* Country Select */}
          <FormField
            control={form.control}
            name='subscribe_to_notification'
            render={({field}) => (
              <FormItem>
                <FormControl>
                  <Select
                    onValueChange={(val) => field.onChange(val === 'true')}
                    value={field.value ? 'true' : 'false'}
                  >
                    <SelectTrigger className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'>
                      <SelectValue placeholder='Please Select.' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value='true'>True</SelectItem>
                      <SelectItem value='false'>False</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Submit Button */}
          <Button
            className='col-span-2 p-[16px] w-full h-[48px] bg-[#000A11] dark:bg-gray-700 rounded-[12px] font-medium text-[14px] leading-[140%] text-[#FFFFFF]'
            type='submit'
          >
            Submit
          </Button>
        </form>
      </Form>
    </div>
  )
}
