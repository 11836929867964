import Cookies from 'js-cookie'
import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'

export default function PrivateLayout() {
  const token = Cookies.get('kotha_token')

  useEffect(() => {
    if (!token) {
      // router.push("signin");
      window.location = '/signin'
    }
  }, [token])

  return (
    <div className='bg-[#F3F3F3]'>
      <Outlet />
    </div>
  )
}
