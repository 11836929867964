import {BsInfoCircle} from 'react-icons/bs'
import {FaEllipsisVertical, FaPlus} from 'react-icons/fa6'
import {ScrollArea} from '../ui/scroll-area'
import SettingMenu from './SettingMenu'

export default function ChatList({
  chatHistory,
  selectedChat,
  timestamp,
  setTimestamp,
}) {
  return (
    <>
      {/* New Chat button */}
      <div className='p-4 h-[77vh]'>
        <button
          aria-label='button'
          className='flex items-center justify-center  gap-2 w-full md:w-[290px] h-[40px] rounded-[8px] font-medium 
          text-[14px] leading-[140%] text-white bg-[#000A11] dark:bg-white dark:text-[#000A11]'
          type='button'
          id='button-addon2'
          disabled={chatHistory?.length === 0}
          onClick={() => {
            setTimestamp(Date.now())
          }}
        >
          <FaPlus />
          New Chat
        </button>

        {/* Chat list scrollable area */}
        <ScrollArea className='h-[75%] md:h-[442px] py-4 md:w-[290px]'>
          {chatHistory?.length ? (
            <>
              {chatHistory.map((chat, index) => (
                <li
                  key={index}
                  className={`flex items-center justify-between px-[12px] gap-[10px] h-[40px] cursor-pointer ${
                    chat?.session_id === timestamp
                      ? 'bg-[#E4E4E4] dark:bg-zinc-700 dark:text-white rounded-[8px]'
                      : 'border-b dark:border-zinc-700 rounded-none'
                  } `}
                  onClick={() => {
                    setTimestamp(chat.session_id)
                  }}
                >
                  <div className='line-clamp-1'>
                    {chat?.chats?.at(0)?.user_query}
                  </div>
                  <FaEllipsisVertical />
                </li>
              ))}
            </>
          ) : null}
        </ScrollArea>
      </div>

      {/* Settings and Help section */}
      <div className='flex flex-col items-start border-t dark:border-zinc-700 border-[#E4E4E4] fixed h-[6.6rem] bottom-0 w-[327px]'>
        <ui className='list-none mb-0 mt-2 chat-users-tab'>
          {/* Help item */}
          <li className='flex items-center p-[12px] gap-[10px] rounded-[8px] dark:text-white'>
            <BsInfoCircle /> Help
          </li>

          {/* Settings dialog trigger */}
          <SettingMenu />
        </ui>
      </div>
    </>
  )
}
