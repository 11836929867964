import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {z} from 'zod'
import {useToast} from '../../components/hooks/use-toast'
import {Button} from '../../components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../../components/ui/form'
import {Input} from '../../components/ui/input'
import {useChannelSettings} from '../../context/ChannelSettings'
import {PASSWORD_RESET_CONFIRM} from '../../scripts/api'
import {postData} from '../../scripts/api-service'

const formSchema = z.object({
  new_password1: z
    .string()
    .min(6, {message: 'Password must be at least 6 characters'}),
  new_password2: z.string().min(6, {message: 'Password must match'}),
})
export default function ResetPassword() {
  const {channelSettings} = useChannelSettings()
  const navigate = useNavigate()
  const {toast} = useToast()
  const {uid, token} = useParams()
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      new_password1: '',
      new_password2: '',
    },
  })

  const onSubmitConfirm = async (data) => {
    let formData = {...data, ...{uid: uid, token: token}}
    let res = await postData(PASSWORD_RESET_CONFIRM, formData)

    if (res) {
      toast({
        variant: 'success',
        title: res?.data?.detail,
      })

      navigate('/signin')
    }
  }

  return (
    <div className='bg-white dark:bg-gray-900 my-20 gap-6 md:mx-5 w-full md:w-[460px] border border-[#E4E4E4] dark:border-gray-600 rounded-[16px] p-[24px] flex flex-col items-center'>
      <Link to='/'>
        <img
          src={channelSettings?.logo_dark}
          className='mx-auto md:w-[72px] md:h-[72px]'
          alt='logo'
        />
      </Link>

      <div className='font-bold text-[24px] leading-[150%] text-[#000A11] dark:text-white'>
        Reset Password
      </div>
      <div className='w-[330px] md:w-[410px]'>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmitConfirm)}
            className='space-y-4'
          >
            {/* Password Inputs */}
            <FormField
              control={form.control}
              name='new_password1'
              render={({field}) => (
                <FormItem>
                  {/* <FormLabel>Password</FormLabel> */}
                  <FormControl>
                    <Input
                      className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'
                      type='password'
                      placeholder='Enter password'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='new_password2'
              render={({field}) => (
                <FormItem>
                  {/* <FormLabel>Confirm Password</FormLabel> */}
                  <FormControl>
                    <Input
                      className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'
                      type='password'
                      placeholder='Confirm password'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Submit Button */}
            <Button className='w-full' type='submit'>
              Confirm Password
            </Button>
          </form>
        </Form>

        <div className='text-center mt-4'>
          Have an account?
          <Link
            to='/signin'
            className='font-medium text-[14px] leading-[140%] text-[#000A11] dark:text-gray-400'
          >
            {' '}
            Sign in
          </Link>
        </div>
      </div>
    </div>
  )
}
