import {useEffect, useState} from 'react'
import {useSpeech} from 'react-text-to-speech'
import {useChannelSettings} from '../../context/ChannelSettings'
import {LIVE_CHAT} from '../../scripts/api'
import {postData} from '../../scripts/api-service'
import {ScrollArea} from '../ui/scroll-area'

export default function ChatContent({
  selectedChat,
  setSelecetdChat,
  timestamp,
  username,
}) {
  const {channelSettings} = useChannelSettings()
  const [showChat, setShowChat] = useState(false)
  const [showLoader, setShowLoader] = useState(false)

  const handelSubmit = async () => {
    let doc = document.getElementById('js-msg-input')
    if (doc.value.trim()) {
      onSubmit(doc)
    }
  }

  const autoStartChat = async (text) => {
    console.log(text)
    onSubmit(null, text)
  }

  const onSubmit = async (doc, text) => {
    let userInput = doc?.value ? doc.value.trim() : text

    console.log('userInput', userInput)

    if (userInput) {
      setShowLoader(true)

      setSelecetdChat((prevItems) => [
        ...prevItems,
        {prompt: userInput, user_query: userInput},
      ])

      if (doc) {
        doc.value = ''
      }

      let res = await postData(LIVE_CHAT, [
        {
          userInput: userInput,
          sessionId: timestamp,
          channel: 'kotha',
        },
      ])

      if (res) {
        setShowLoader(false)
        scrollToBottom()
        setSelecetdChat((prevItems) => [
          ...prevItems,
          {response: res?.data?.response, user_query: userInput},
        ])
        if (!showChat) {
          setShowChat(true)
        }
      }
    }
  }

  useEffect(() => {
    if (selectedChat && selectedChat?.length) {
      setShowChat(true)
      setTimeout(() => {
        scrollToBottom()
      }, 500)
    } else {
      setShowChat(false)
    }
  }, [selectedChat])

  const scrollToBottom = () => {
    document.getElementById('js-chat-content').scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'end',
    })
  }

  return (
    <>
      <div
        className='chat-content h-[80vh] md:h-[77vh]  dark:bg-zinc-900 dark:text-white'
        id='main-chat-content'
        data-simplebar='init'
      >
        {showChat ? (
          <>
            <ScrollArea className='h-full  dark:dark'>
              <div
                id='js-chat-content'
                className='flex flex-col items-start gap-6 p-6'
              >
                {selectedChat?.length ? (
                  <>
                    {selectedChat.map((item) => (
                      <>
                        {item?.prompt ? (
                          <ChatMessage
                            userImage='/assets/images/user.png'
                            message={item.prompt}
                            // userIcon="/assets/icons/edit.svg"
                            additionalIcons={[
                              {
                                value: 'edit',
                                icon: '/assets/icons/edit.svg',
                              },
                            ]}
                          />
                        ) : null}

                        {item?.response ? (
                          <ChatMessage
                            userImage='/assets/images/logo.png'
                            message={item.response}
                            prompt={item.prompt}
                            onSubmit={onSubmit}
                            // userIcon="/assets/icons/voice.svg"
                            additionalIcons={[
                              {
                                value: 'voice',
                                icon: '/assets/icons/voice.svg',
                              },
                              {
                                value: 'reset',
                                icon: '/assets/icons/reset.svg',
                              },
                              {
                                value: 'copy',
                                icon: '/assets/icons/copy.svg',
                              },
                              // {
                              //   value: 'love',
                              //   icon: '/assets/icons/love.svg',
                              // },
                            ]}
                          />
                        ) : null}
                      </>
                    ))}
                  </>
                ) : null}

                {showLoader ? (
                  <ChatLoading
                    userImage='/assets/images/logo.png'
                    userIcon='/assets/icons/voice.svg'
                  />
                ) : null}
              </div>
            </ScrollArea>
          </>
        ) : (
          <div className='overflow-auto h-full '>
            <div className='flex flex-col items-center p-[100px_24px_24px] gap-[24px]'>
              <div>
                <img
                  src='/assets/images/kotha.png'
                  alt='kotha'
                  width={80} // Equivalent to w-20 (20 * 4 = 80px)
                  height={80} // Equivalent to h-20 (20 * 4 = 80px)
                />
              </div>
              <div className='font-bold text-[24px] leading-[150%] text-center text-[#000A11] dark:text-white'>
                <h1>Hello{username}</h1>
                <h1>How can I help you today?</h1>
              </div>
              <div className='flex flex-row flex-wrap justify-center items-start gap-4'>
                {channelSettings?.prompts?.length ? (
                  <>
                    {channelSettings?.prompts?.map((item) => (
                      <ActionCard
                        text={item.query}
                        autoStartChat={autoStartChat}
                      />
                    ))}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='fixed webkit-fill-available bottom-0 flex items-start p-6 gap-6 bg-white border-t border-[#E4E4E4] dark:bg-zinc-800 dark:border-zinc-700'>
        <div className='w-full'>
          <input
            id='js-msg-input'
            className='h-14 w-full flex items-center px-4 gap-2 bg-[#F9F9F9] border border-[#E4E4E4] rounded-lg dark:bg-zinc-950 dark:border-zinc-700 dark:text-white dark:placeholder-zinc-300'
            placeholder='Write here.'
            type='text'
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handelSubmit()
              }
            }}
          />
        </div>
        <div>
          <img
            src='/assets/images/send.png'
            alt='send'
            width={60} // Provide the actual width of the image (adjust as necessary)
            height={60} // Provide the actual height of the image (adjust as necessary)
            className='cursor-pointer'
            onClick={handelSubmit}
          />
        </div>
      </div>
    </>
  )
}

const ChatLoading = ({userImage}) => (
  <div className='flex items-start gap-4'>
    <div className='w-10 h-10 contents'>
      <img src={userImage} alt='user' className='w-10 h-10' />
    </div>
    <div className='flex flex-col md:flex-row items-start gap-4'>
      <div className='md:max-w-[70%] p-4 border border-[#E4E4E4] rounded-lg font-medium text-[14px] leading-[140%] text-[#000A11] bg-white dark:bg-zinc-800 dark:text-white'>
        <div className='chat-loader'>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
)

const ChatMessage = ({
  userImage,
  message,
  prompt,
  additionalIcons,
  onSubmit,
}) => {
  const [voicetext, setVoiceText] = useState('')

  const {Text, speechStatus, isInQueue, start, pause, stop} = useSpeech({
    text: voicetext,
    pitch: 1,
    rate: 1,
    volume: 1,
    lang: 'en-US',
    voiceURI: 'Google US English',
    autoPlay: true,
    highlightText: false,
    maxChunkSize: 100,
  })

  const handelAction = (context, message) => {
    if (context === 'copy') {
      navigator.clipboard.writeText(message)
    } else if (context === 'voice') {
      setVoiceText(message)
      if (message === voicetext) {
        pause()
      }
    } else if (context === 'reset') {
      onSubmit(null, prompt)
    } else if (context === 'edit') {
      // onSubmit(message, prompt)
      let doc = document.getElementById('js-msg-input')
      if (doc) {
        doc.value = message
      }
    }
  }

  useEffect(() => {
    if (voicetext) {
      start()
    }
  }, [voicetext])

  return (
    <div className='flex items-start gap-4'>
      <div className='w-10 h-10 contents'>
        <img
          src={userImage}
          alt='user'
          width={40} // Equivalent to w-10 (10 * 4 = 40px)
          height={40} // Equivalent to h-10 (10 * 4 = 40px)
        />
      </div>
      <div className='flex flex-col md:flex-row items-start gap-4'>
        <div className='md:max-w-[70%] whitespace-pre-line p-4 border border-[#E4E4E4] rounded-lg font-medium text-[14px] leading-[140%] text-[#000A11] bg-white dark:bg-zinc-800 dark:text-white'>
          {message}
        </div>
        <div className='flex items-start gap-4'>
          {additionalIcons?.map((item, index) => (
            <img
              key={index}
              src={item.icon}
              alt={item.value}
              width={32} // Replace with the actual width or make it dynamic
              height={32} // Replace with the actual height or make it dynamic
              className='cursor-pointer'
              onClick={() => handelAction(item.value, message)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const ActionCard = ({text, autoStartChat}) => (
  <div
    onClick={() => {
      autoStartChat(text)
    }}
    className='p-4 cursor-pointer flex flex-col md:flex-row justify-center items-center gap-[10px] w-[231px] h-[112px] text-center border border-[#E4E4E4] rounded-lg font-medium text-[14px] leading-[140%] text-[#000A11] dark:text-white bg-white dark:bg-zinc-800'
  >
    {text}
  </div>
)
