import {zodResolver} from '@hookform/resolvers/zod'
import {useState} from 'react'
import {useForm} from 'react-hook-form'
import {Link, useNavigate} from 'react-router-dom'
import {z} from 'zod'
import {useToast} from '../../components/hooks/use-toast'
import {Button} from '../../components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../../components/ui/form'
import {Input} from '../../components/ui/input'
import {useChannelSettings} from '../../context/ChannelSettings'
import {REGISTER} from '../../scripts/api'
import {postData} from '../../scripts/api-service'

// Define the validation schema using zod
const formSchema = z.object({
  email: z.string().email({message: 'Please enter a valid email'}),
  password1: z
    .string()
    .min(6, {message: 'Password must be at least 6 characters'}),
  password2: z.string().min(6, {message: 'Password must match'}),
})

export default function Signup() {
  const {channelSettings} = useChannelSettings()
  const navigate = useNavigate()
  const {toast} = useToast()
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password1: '',
      password2: '',
    },
  })

  const [showVerifyNumber, setShowVerifyNumber] = useState(false)

  const onSubmit = async (data) => {
    let res = await postData(REGISTER, data)

    if (res) {
      if (res.error) {
        Object.keys(res.errors).forEach((field) => {
          if (field === 'non_field_errors') {
            toast({
              variant: 'destructive',
              title: res.errors[field],
            })
          } else {
            form.setError(field, {
              type: 'manual',
              message: res.errors[field],
            })
          }
        })
      } else {
        let masterData = res?.data
        toast({
          variant: 'success',
          title: masterData?.detail,
        })
        navigate('/signin')
      }
    }
  }

  const handleSubmit = () => {}

  return (
    <div className='bg-white dark:bg-gray-900 my-20 gap-6 md:mx-5 w-full md:w-[460px] border border-[#E4E4E4] dark:border-gray-600 rounded-[16px] p-[24px] flex flex-col items-center'>
      <Link to='/'>
        <img
          src={channelSettings?.logo_dark}
          alt={'logo'}
          className='mx-auto w-[150px] h-[40px]'
        />{' '}
      </Link>

      {showVerifyNumber ? (
        <>
          <div className='font-bold text-[24px] leading-[150%] text-[#000A11] dark:text-white'>
            Verify number
          </div>

          <div className='font-normal text-[16px] leading-[170%] text-[#2D2D2D] dark:text-gray-400'>
            We sent a verification code to 01628374708
          </div>

          <div className='w-auto md:w-[410px]'>
            <form
              autoComplete='off'
              onSubmit={handleSubmit(onSubmit)}
              className='flex flex-col items-center p-0 gap-[16px]'
            >
              <div className='form-group w-full'>
                <input
                  className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'
                  placeholder='OTP code'
                  type='text'
                  id='otp'
                  autoComplete='off'
                  // {...register('otp', {required: 'OTP code is required'})}
                />
                {/* {errors.otp && (
                  <p className='text-red-600 dark:text-red-400'>
                    {errors.otp.message}
                  </p>
                )} */}
              </div>

              <button
                type='submit'
                className='p-[16px] w-full h-[48px] bg-[#000A11] dark:bg-gray-700 rounded-[12px] font-medium text-[14px] leading-[140%] text-[#FFFFFF]'
              >
                Verify
              </button>

              <div>
                <Link
                  to='/signin'
                  className='font-medium text-[14px] leading-[140%] text-[#000A11] dark:text-gray-400'
                >
                  Have an account? Sign in
                </Link>
              </div>
            </form>
          </div>
        </>
      ) : (
        <>
          <div className='font-bold text-[24px] leading-[150%] text-[#000A11] dark:text-white'>
            Sign up to Kotha.ai
          </div>

          <div className='w-[330px] md:w-[410px]'>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className='space-y-4'
              >
                {/* Email Input */}
                <FormField
                  control={form.control}
                  name='email'
                  render={({field}) => (
                    <FormItem>
                      {/* <FormLabel>Email</FormLabel> */}
                      <FormControl>
                        <Input
                          className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'
                          placeholder='Email'
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {/* Password Inputs */}
                <FormField
                  control={form.control}
                  name='password1'
                  render={({field}) => (
                    <FormItem>
                      {/* <FormLabel>Password</FormLabel> */}
                      <FormControl>
                        <Input
                          className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'
                          type='password'
                          placeholder='Enter password'
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='password2'
                  render={({field}) => (
                    <FormItem>
                      {/* <FormLabel>Confirm Password</FormLabel> */}
                      <FormControl>
                        <Input
                          className='bg-[#F9F9F9] dark:bg-gray-800 border border-[#E4E4E4] dark:border-gray-600 rounded-[12px] p-[16px] w-full text-[#000A11] dark:text-white'
                          type='password'
                          placeholder='Confirm password'
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {/* Submit Button */}
                <Button className='w-full' type='submit'>
                  Sign up
                </Button>
              </form>
            </Form>
          </div>
        </>
      )}

      <div>
        Have an account?
        <Link
          to='/signin'
          className='font-medium text-[14px] leading-[140%] text-[#000A11] dark:text-gray-400'
        >
          {' '}
          Sign in
        </Link>
      </div>
    </div>
  )
}
